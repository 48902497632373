/** @jsx jsx */
import { jsx, Flex, Paragraph } from "theme-ui"
import { PropTypes } from "prop-types"

import theme from "../../gatsby-plugin-theme-ui"
import {
  PrismicRichTextFieldPropType,
  PrismicPlainTextFieldPropType,
} from "../../utils/propTypes"
import { Article } from "../../enums/Article"
import { RichText } from "../RichText"
import { ArticleQuotePropTypes } from "../../utils/propTypes"
import quoteLeftLeaf from "../../images/quote-left-leaf.svg"
import quoteRightLeaf from "../../images/quote-right-leaf.svg"

export const ArticleQuoteBase = ({
  quote,
  quoteAttribution,
  quoteStyles,
  quoteDividerStyles,
}) => (
  <Flex
    sx={{
      flexDirection: "column",
      justifyContent: "center",
      flex: 1,
      ...quoteStyles,
    }}
  >
    <RichText sxOverrides={{ variant: "text.quote" }} text={quote} />
    {quoteAttribution.text ? (
      <Paragraph variant="quoteAttribution" sx={quoteDividerStyles}>
        &mdash; {quoteAttribution.text}
      </Paragraph>
    ) : (
      <hr sx={quoteDividerStyles} />
    )}
  </Flex>
)
ArticleQuoteBase.propTypes = {
  quote: PrismicRichTextFieldPropType.isRequired,
  quoteAttribution: PrismicPlainTextFieldPropType.isRequired,
  quoteStyles: PropTypes.object,
  quoteDividerStyles: PropTypes.object,
}

const containerStyles = stylePosition => {
  const baseStyles = {
    flexDirection: "column",
    ...theme.articlePageStyles.container.base,
  }

  switch (stylePosition) {
    case Article.StylePositionQuote.LEFT:
      return {
        justifyContent: "flex-start",
        textAlign: "left",
        ...baseStyles,
      }
    case Article.StylePositionQuote.RIGHT:
      return {
        justifyContent: "flex-end",
        textAlign: "right",
        ...baseStyles,
      }
    default:
      return {
        justifyContent: "center",
        textAlign: "center",
        ...baseStyles,
      }
  }
}

export const quoteAttributionBaseStyles = {
  position: "relative",
  pb: 3,
  borderBottomStyle: "solid",
  borderBottomWidth: "2px",
  borderBottomColor: "oliveGreen",
  ":after": {
    content: '""',
    width: "10px",
    height: "17px",
    position: "absolute",
    bottom: 0,
  },
}

export const quoteDividerBaseStyles = {
  position: "relative",
  margin: 0,
  border: "none",
  borderBottomStyle: "solid",
  borderBottomWidth: "2px",
  borderBottomColor: "oliveGreen",
  height: "20px",
  width: "80px",
  ":after": {
    content: '""',
    width: "10px",
    height: "17px",
    position: "absolute",
    bottom: 0,
  },
}

const quoteDividerStyles = (baseStyles, stylePosition) => {
  switch (stylePosition) {
    case Article.StylePositionQuote.LEFT:
      return {
        alignSelf: "flex-start",
        ...baseStyles,
        ":after": {
          ...baseStyles[":after"],
          backgroundImage: `url(${quoteLeftLeaf})`,
          right: "-9px",
        },
      }
    case Article.StylePositionQuote.RIGHT:
      return {
        alignSelf: "flex-end",
        ...baseStyles,
        ":after": {
          ...baseStyles[":after"],
          backgroundImage: `url(${quoteRightLeaf})`,
          left: "-9px",
        },
      }
    default:
      return {
        alignSelf: "center",
        ...baseStyles,
        ":after": {
          ...baseStyles[":after"],
          backgroundImage: `url(${quoteLeftLeaf})`,
          right: "-9px",
        },
      }
  }
}

export const ArticleQuote = ({ primary }) => {
  const { quote, quote_attribution, style_position } = primary

  return (
    <Flex
      sx={{
        ...containerStyles(style_position),
      }}
    >
      <ArticleQuoteBase
        quote={quote}
        quoteAttribution={quote_attribution}
        quoteStyles={{ flex: [0.9, 0.8, 0.8, 0.7] }}
        quoteDividerStyles={quoteDividerStyles(
          quote_attribution.text
            ? quoteAttributionBaseStyles
            : quoteDividerBaseStyles,
          style_position
        )}
      />
    </Flex>
  )
}

ArticleQuote.propTypes = ArticleQuotePropTypes
