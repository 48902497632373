/** @jsx jsx */
import { jsx } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import { ArticleTextPropTypes } from "../../utils/propTypes"
import { Article } from "../../enums/Article"
import { RichText } from "../RichText"

export const ArticleText = ({ primary }) => {
  const { text, style_alignment } = primary

  return (
    <RichText
      sxOverrides={{
        ...theme.articlePageStyles.container.base,
        textAlign:
          style_alignment === Article.StyleAlignmentText.CENTER
            ? "center"
            : "left",
      }}
      text={text}
    />
  )
}

ArticleText.propTypes = ArticleTextPropTypes
