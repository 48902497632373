/** @jsx jsx */
import { jsx } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import { ArticleDividerPropTypes } from "../../utils/propTypes"
import { Divider } from "../Divider"
import { DividerType } from "../../hooks/divider"

export const ArticleDivider = ({ primary }) => {
  const { type } = primary
  const image =
    type.document && Object.keys(type.document).length !== 0 ? type.document.data.image : null

  return (
    <Divider
      type={image ? null : DividerType.DEFAULT}
      image={image}
      styles={{ ...theme.articlePageStyles.containedElement, my: 3 }}
    />
  )
}

ArticleDivider.propTypes = ArticleDividerPropTypes
