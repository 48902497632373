/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Flex } from "theme-ui"
import theme from "../../gatsby-plugin-theme-ui"
import { Article } from "../../enums/Article"
import { ArticleImagesWithQuotePropTypes } from "../../utils/propTypes"
import { ArticleImagesBase, sizes } from "./ArticleImages"
import { ArticleQuoteBase } from "./ArticleQuote"
import quoteLeftLeaf from "../../images/quote-left-leaf.svg"
import {
  quoteDividerBaseStyles,
  quoteAttributionBaseStyles,
} from "./ArticleQuote"

const quoteStyles = stylePosition => {
  return stylePosition ===
    Article.StylePositionImagesWithQuote.IMAGES_LEFT_QUOTE_RIGHT
    ? theme.articlePageStyles.quoteWithElement.quoteRight
    : theme.articlePageStyles.quoteWithElement.quoteLeft
}

const quoteDividerStyles = baseStyles => ({
  alignSelf: "flex-start",
  ...baseStyles,
  ":after": {
    ...baseStyles[":after"],
    backgroundImage: `url(${quoteLeftLeaf})`,
    right: "-9px",
  },
})

const MAX_IMAGES_PER_ROW = [1, 1, 1, 1]
const imageContainerStyles = {
  flex: 1,
}

export const ArticleImagesWithQuote = ({ primary, items }) => {
  const { quote, quote_attribution, style_position } = primary
  const dividerBaseStyles = quote_attribution.text
    ? quoteAttributionBaseStyles
    : quoteDividerBaseStyles

  return (
    <Flex
      sx={{
        flexWrap: "wrap",
        ...theme.articlePageStyles.container.base,
      }}
    >
      {style_position ===
      Article.StylePositionImagesWithQuote.IMAGES_LEFT_QUOTE_RIGHT ? (
        <Fragment>
          <ArticleImagesBase
            items={items}
            maxImagesPerRow={MAX_IMAGES_PER_ROW}
            sizes={sizes(
              MAX_IMAGES_PER_ROW,
              theme.desktopArticleContentWidth / 2,
              null,
              items.length
            )}
            containerStyles={imageContainerStyles}
            imageObjectFit="cover"
          />
          <ArticleQuoteBase
            quote={quote}
            quoteAttribution={quote_attribution}
            quoteStyles={quoteStyles(style_position)}
            quoteDividerStyles={quoteDividerStyles(
              dividerBaseStyles,
              style_position
            )}
          />
        </Fragment>
      ) : (
        <Fragment>
          <ArticleQuoteBase
            quote={quote}
            quoteAttribution={quote_attribution}
            quoteStyles={quoteStyles(style_position)}
            quoteDividerStyles={quoteDividerStyles(
              dividerBaseStyles,
              style_position
            )}
          />
          <ArticleImagesBase
            items={items}
            maxImagesPerRow={MAX_IMAGES_PER_ROW}
            sizes={sizes(
              MAX_IMAGES_PER_ROW,
              theme.desktopArticleContentWidth / 2,
              null,
              items.length
            )}
            containerStyles={imageContainerStyles}
            imageObjectFit="cover"
          />
        </Fragment>
      )}
    </Flex>
  )
}

ArticleImagesWithQuote.propTypes = ArticleImagesWithQuotePropTypes
