/** @jsx jsx */
import { jsx } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import { ArticleCalloutPropTypes } from "../../utils/propTypes"
import { Article } from "../../enums/Article"
import { RichText } from "../RichText"

export const ArticleCallout = ({ primary }) => {
  const { text, style_alignment } = primary

  return (
    <RichText
      sxOverrides={{
        ...theme.articlePageStyles.containedElement,
        a: {
          fontWeight: "bold",
          color: "darkSoilBrown",
          transition: "all 0.1s ease-in",
          "&:hover": {
            color: "oliveGreen",
          },
        },

        my: 5,
        px: 6,
        py: 4,
        backgroundColor: "sandYellow",
        textAlign:
          style_alignment === Article.StyleAlignmentText.CENTER
            ? "center"
            : "left",
      }}
      text={text}
    />
  )
}

ArticleCallout.propTypes = ArticleCalloutPropTypes
