/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Flex } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import { Article } from "../../enums/Article"
import {
  PrismicRichTextFieldPropType,
  ArticleTextWithQuotePropTypes,
} from "../../utils/propTypes"
import { ArticleQuoteBase, quoteAttributionBaseStyles } from "./ArticleQuote"
import quoteLeftLeaf from "../../images/quote-left-leaf.svg"
import { RichText } from "../RichText"
import { quoteDividerBaseStyles } from "./ArticleQuote"

const quoteStyles = stylePosition => {
  return stylePosition ===
    Article.StylePositionTextWithQuote.TEXT_LEFT_QUOTE_RIGHT
    ? theme.articlePageStyles.quoteWithElement.quoteRight
    : theme.articlePageStyles.quoteWithElement.quoteLeft
}

const quoteDividerStyles = baseStyles => ({
  alignSelf: "flex-start",
  ...baseStyles,
  ":after": {
    ...baseStyles[":after"],
    backgroundImage: `url(${quoteLeftLeaf})`,
    right: "-9px",
  },
})

const Text = ({ text }) => (
  <RichText
    sxOverrides={{
      flex: 2,
      // Keeping the text and quote side-by-side on mobile is too squeezy,
      // So we make the rightmost element overflow to the next row
      minWidth: ["100%", 0, 0, 0],
    }}
    text={text}
  />
)
Text.propTypes = {
  text: PrismicRichTextFieldPropType.isRequired,
}

export const ArticleTextWithQuote = ({ primary }) => {
  const { quote, quote_attribution, text, style_position } = primary
  const dividerBaseStyles = quote_attribution.text
    ? quoteAttributionBaseStyles
    : quoteDividerBaseStyles

  return (
    <Flex
      sx={{
        ...theme.articlePageStyles.container.base,
        flexWrap: "wrap",
      }}
    >
      {style_position ===
      Article.StylePositionTextWithQuote.TEXT_LEFT_QUOTE_RIGHT ? (
        <Fragment>
          <Text text={text} />
          <ArticleQuoteBase
            quote={quote}
            quoteAttribution={quote_attribution}
            quoteStyles={quoteStyles(style_position)}
            quoteDividerStyles={quoteDividerStyles(
              dividerBaseStyles,
              style_position
            )}
          />
        </Fragment>
      ) : (
        <Fragment>
          <ArticleQuoteBase
            quote={quote}
            quoteAttribution={quote_attribution}
            quoteStyles={quoteStyles(style_position)}
            quoteDividerStyles={quoteDividerStyles(
              dividerBaseStyles,
              style_position
            )}
          />
          <Text text={text} />
        </Fragment>
      )}
    </Flex>
  )
}

ArticleTextWithQuote.propTypes = ArticleTextWithQuotePropTypes
