/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { PropTypes } from "prop-types"

import { Article } from "../../enums/Article"
import theme from "../../gatsby-plugin-theme-ui"
import {
  ArticleImagesPropTypes,
  PrismicRichTextFieldPropType,
} from "../../utils/propTypes"
import { ResponsiveImage } from "../ResponsiveImage"
import { RichText } from "../RichText"

const imageObjectFit = (numItems, styleAlignment) => {
  return numItems === 1 &&
    styleAlignment === Article.StyleAlignmentImages.CONTAINED
    ? "contain"
    : "cover"
}

const containerStyles = styleAlignment => {
  const baseStyles = theme.articlePageStyles.container.base
  const paddingStyles = theme.articlePageStyles.container[styleAlignment] || {}

  return { justifyContent: "center", ...baseStyles, ...paddingStyles }
}

export const sizes = (
  maxImagesPerRow,
  maxContentWidth,
  styleAlignment,
  numItems
) => {
  if (styleAlignment === Article.StyleAlignmentImages.FULL_WIDTH) {
    return maxImagesPerRow.map(max => `${100 / Math.min(numItems, max)}vw`)
  }

  const finalMaxContentWidth =
    maxContentWidth || theme.desktopArticleContentWidth
  return maxImagesPerRow.map(
    max => `${finalMaxContentWidth / Math.min(numItems, max)}rem`
  )
}

const minImageContainerWidth = (imageItems, maxImagesPerRow) =>
  maxImagesPerRow.map(
    max => `max(calc(100% / ${imageItems.length}), calc(100% / ${max}))`
  )

export const imageContainerStyles = (index, imageItems, maxImagesPerRow) => {
  const paddings = [2, 2, 2, 3]
  const baseStyle = {
    minWidth: minImageContainerWidth(imageItems, maxImagesPerRow),
    pb: paddings,
  }
  const rangeArray = Array(theme.breakpoints.length + 1)
    .fill(0)
    .map((_, i) => i)

  const shouldPadXs = rangeArray.map(
    i =>
      index !== imageItems.length - 1 && (index + 1) % maxImagesPerRow[i] !== 0
  )

  return {
    ...baseStyle,
    pr: shouldPadXs.map((shouldPadX, i) => (shouldPadX ? paddings[i] : 0)),
  }
}

export const ArticleImagesBase = ({
  caption,
  items,
  sizes,
  maxImagesPerRow,
  containerStyles,
  imageObjectFit,
}) => {
  return (
    <div
      data-testid="images-container"
      sx={{
        textAlign: "center",
        // For mobile portrait, the images and text
        // should occupy the whole viewport width
        minWidth: ["100%", "auto", "auto"],
        ...containerStyles,
      }}
    >
      <Flex
        sx={{
          flexWrap: "wrap",
        }}
      >
        {items.map((item, i) => (
          <Flex
            key={i}
            sx={{
              flex: 1,
              ...imageContainerStyles(i, items, maxImagesPerRow),
            }}
          >
            <ResponsiveImage
              image={item.image}
              sizes={sizes}
              sx={{
                flex: 1,
                maxHeight: ["800px", "400px", "800px"],
              }}
              objectFit={imageObjectFit}
            />
          </Flex>
        ))}
      </Flex>
      {caption?.richText? (
        <RichText
          sxOverrides={{
            variant: "text.fineprint",
            color: "gray",
          }}
          text={caption}
        />
      ) : null}
    </div>
  )
}
ArticleImagesBase.propTypes = {
  styleAlignment: PropTypes.oneOf(Object.values(Article.StyleAlignmentImages)),
  caption: PrismicRichTextFieldPropType,
  items: ArticleImagesPropTypes.items,
  // This is an array of numbers according to the theme's breakpoints
  maxImagesPerRow: PropTypes.arrayOf(PropTypes.number),
  // Responsive array of sizes
  sizes: PropTypes.array,
  containerStyles: PropTypes.object,
  imageObjectFit: PropTypes.string,
}

const MAX_IMAGES_PER_ROW = [2, 3, 4, 4]
export const ArticleImages = ({ primary, items }) => {
  const { style_alignment, caption } = primary

  return (
    <ArticleImagesBase
      caption={caption}
      items={items}
      maxImagesPerRow={MAX_IMAGES_PER_ROW}
      sizes={sizes(MAX_IMAGES_PER_ROW, null, style_alignment, items.length)}
      containerStyles={containerStyles(style_alignment)}
      imageObjectFit={imageObjectFit(items.length, style_alignment)}
    />
  )
}

ArticleImages.propTypes = ArticleImagesPropTypes
