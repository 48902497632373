/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { transparentize } from "@theme-ui/color"
import Slider from "react-slick"

import theme from "../../gatsby-plugin-theme-ui"
import { renderArrowProps } from "../CarouselArrow"
import { CarouselIndicator } from "../CarouselIndicator"
import {
  PrismicPlainTextFieldPropType,
  ArticleImageGalleryPropTypes,
} from "../../utils/propTypes"
import { heightFromDeviceHeight } from "../../utils/styleUtils"
import { ResponsiveImage } from "../ResponsiveImage"
import { RichText } from "../RichText"

const Caption = ({ caption }) => {
  if (!caption) return null
  return (
    <figcaption>
      <Flex
        sx={{
          variant: "text.fineprint",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          bottom: 0,
          width: "100vw",
          minHeight: "min(40%, 200px)",
          py: 4,
          px: theme.articlePageStyles.container.base.px,
          color: "white",
          background: t => `
      linear-gradient(
        to bottom,
        ${transparentize("darkSoilBrown", 1)(t)} 0%,
        ${transparentize("darkSoilBrown", 0.2)(t)} 50%,
        ${transparentize("darkSoilBrown", 0)(t)}
      )
    `,
        }}
      >
        <RichText
          sxOverrides={{
            variant: "text.fineprint",
          }}
          text={caption}
        />
      </Flex>
    </figcaption>
  )
}
Caption.propTypes = {
  caption: PrismicPlainTextFieldPropType,
}

export const ArticleImageGallery = ({ primary, items }) => {
  const { autoplay } = primary

  return (
    <Slider
      arrows={true}
      {...renderArrowProps}
      dots={true}
      infinite={true}
      autoplay={true}
      swipeToSlide={true}
      slidesToShow={1}
      customPaging={index => <CarouselIndicator index={index} />}
      autoPlay={autoplay}
    >
      {items.map((item, i) => {
        return (
          <figure
            key={i}
            sx={{ position: "relative", textAlign: "center", m: 0 }}
          >
            <ResponsiveImage
              image={item.image}
              sx={{ ...heightFromDeviceHeight(1) }}
            />
            <Caption caption={item.caption} />
          </figure>
        )
      })}
    </Slider>
  )
}

ArticleImageGallery.propTypes = ArticleImageGalleryPropTypes
